<template>
<div>
  <v-simple-table :key="reloadKey">
    <template v-slot:default>
      <thead>
        <tr class="totals">
          <td class="text-left">
            Crédito
          </td>
          <td class="text-left">
            Fecha pago
          </td>
          <td class="text-left">
            Capital
          </td>
          <td class="text-left">
            Interes
          </td>
          <td class="text-left">
            Mora
          </td>
          
          <td class="text-left" v-for="c in concepts">
            {{c.name}}
          </td>
          <td>Total
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
        >
          <td>{{ item.bus_solicitud_id }}</td>
          <td>{{ $filters.date(item.fecha_pago) }}</td>
          <td>{{ item.capital|currency }}</td>
          <td>{{ item.interes|currency }}</td>
          <td>{{ item.mora|currency }}</td>
          
          <td class="text-left" v-for="c in concepts">
           <span v-if="item['c'+c.id]"> {{item['c'+c.id].total|currency}}</span>
          </td>
          <td>{{ item.total|currency }}</td>
          
        </tr>
        
        <tr class="totals">
          <td>Total</td>
          <td></td>
          <td>{{ totales.capital|currency }}</td>
          <td>{{ totales.interes|currency }}</td>
          <td>{{ totales.mora|currency }}</td>
          
          <td class="text-left" v-for="c in concepts">
            {{totales['c'+c.id]|currency}}
          </td>
          <td>{{ totales.total|currency }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </div>

</template>

<script>

export default {
  name: 'liquidacion-detalle',
  data() {
    return {
      reloadKey: 0,
      totales: { capital: 0, interes: 0, mora: 0, total: 0},
      items: [],
      concepts: {}
    }
  },
  props: ['id'],
  mounted(){
    this.$http.get( 'api/admin/liquidacion/detalle/'+this.id  )
        .then(res => {
            if(res.data.success){
              this.items = res.data.items;
              this.concepts = res.data.concepts;
              for(const i in this.concepts){
                this.totales['c'+i] = 0;
              }
              for(const item of this.items){
                this.totales.capital += parseFloat(item.capital);
                this.totales.interes += parseFloat(item.interes);
                this.totales.mora += parseFloat(item.mora);
                this.totales.total += parseFloat(item.total);
                for(const i in this.concepts){
                  //console.log(i)
                  if(item['c'+i]){
                    this.totales['c'+i] += parseFloat(item['c'+i].total);
                  }
                }
              }
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
  },
}
</script>
