<template>
  <div>
    <default-app-bar>
      <template v-slot:title>
        <div class="text-h3 mr-2"> Detalle Liquidación </div>
      </template>


      <template v-slot:actions>
        <router-link to="/admin/liquidacion/list" class="mr-2 white-text">
          Volver
        </router-link>



      </template>
    </default-app-bar>



    <v-expansion-panels v-model="panel" multiple v-if="info" :key="reloadKey">
      <v-expansion-panel>
        <v-expansion-panel-header color="primary" class="white-text">
          Liquidación Administración de Cartera
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card style="width: 480px">
            <v-card-text v-if="info">
              <!--div class="text-h6">Liquidación Administración de Cartera</div-->

              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="totals">No. liquidación</td>
                      <td>{{ info.id }}</td>
                    </tr>
                    <tr>
                      <td class="totals">Estado</td>
                      <td><span v-if="!info.pagada">Generada</span><span v-else>Pagada</span></td>
                    </tr>
                    <tr v-if="info.documentos_adjuntos">
                      <td class="totals">Comprobante</td>
                      <td>
                        <template v-for="doc in info.documentos_adjuntos">
                          <span v-if="doc.path"><a v-bind:href="'https://app.serprogreso.com/' + doc.path"
                              target="_blank">Ver</a></span>
                        </template>
                      </td>
                    </tr>

                    <tr v-if="info.bus_convenio_admin_cartera_id">
                      <td class="totals">Cliente</td>
                      <td>{{ info.bus_convenio_admin_cartera_id.name }}</td>
                    </tr>
                    <tr>
                      <td class="totals">Periodo de liquidación</td>
                      <td>{{ info.periodo }}</td>
                    </tr>
                    <tr>
                      <td class="totals">Fecha de generación</td>
                      <td>{{ $filters.date(info.fecha_generacion) }}</td>
                    </tr>
                    <tr>
                      <td class="totals">Fecha límite de pago</td>
                      <td>{{ $filters.date(info.fecha_limite_pago) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>


              <v-simple-table dense class="mt-4">
                <template v-slot:default>

                  <tbody>
                    <tr class="totals">
                      <td colspan="2" class="text-center">Liquidación</td>
                    </tr>
                    <tr>
                      <td class="totals">No de pagos recibidos</td>
                      <td class="text-right totals">{{ info.numero_pagos }}</td>
                    </tr>
                    <tr class="totals">
                      <td>Detalle de pagos</td>
                      <td class="text-right">Valor</td>
                    </tr>
                    <tr v-for="c in info.otros">
                      <td>{{ c.name }}</td>
                      <td class="text-right">{{ $filters.currency(c.total) }}</td>
                    </tr>

                    <tr>
                      <td>Interes corriente</td>
                      <td class="text-right">{{ $filters.currency(info.interes) }}</td>
                    </tr>
                    <tr>
                      <td>Interes de mora</td>
                      <td class="text-right">{{ $filters.currency(info.mora) }}</td>
                    </tr>
                    <tr>
                      <td>Abono a capital</td>
                      <td class="text-right">{{ $filters.currency(info.capital) }}</td>
                    </tr>
                    <tr class="totals">
                      <td>Valor recaudado(=)</td>
                      <td class="text-right">{{ $filters.currency(info.total_recaudo) }}</td>
                    </tr>
                    <tr class="totals">
                      <td colspan="2">Descuentos</td>

                    </tr>
                    <tr v-for="desc in info.descuentos_generales">
                      <td>{{ desc.name }}</td>
                      <td class="text-right">{{ $filters.currency(desc.subtotal) }}</td>
                    </tr>

                    <tr v-for="c in getRowsGastosAdministrativos()">
                      <td>{{ c.name }}</td>
                      <td class="text-right">{{ c.total | currency }}</td>
                    </tr>

                    <tr>
                      <td>IVA</td>
                      <td class="text-right">{{ $filters.currency(info.impuestos_totales) }}</td>
                    </tr>
                    <tr>
                      <td>Total descuentos (=) </td>
                      <td class="text-right">{{ $filters.currency(getTotalDescuentosGenerales()) }}</td>
                    </tr>
                    <tr class="totals">
                      <td>Subtotal a transferir (=) </td>
                      <td class="text-right"><b>{{ $filters.currency(getSubtotalATransferir()) }}</b></td>
                    </tr>
                    <tr v-for="desc in info.descuentos_finales">
                      <td>{{ desc.name }}</td>
                      <td class="text-right">{{ $filters.currency(desc.total) }}</td>
                    </tr>
                    <tr class="totals">
                      <td>Total a transferir</td>
                      <td class="text-right">{{ $filters.currency(info.total_a_trasferir - getTotalGastosAdministrativos())
                      }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header color="primary" class="white-text">
          Detalle Liquidación (Pagos recaudados)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ShowLiquidacionDetalle :id="info.id"></ShowLiquidacionDetalle>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>



  </div>
</template>

<script>

import ShowLiquidacionDetalle from './ShowLiquidacionDetalle';
export default {
  name: 'ShowLiquidacion',
  components: { ShowLiquidacionDetalle },
  data() {
    return {
      reloadKey: 1,
      panel: [0],
      info: {}
    }
  },
  mounted() {
    this.loadItem();
  },
  methods: {
    getSubtotalATransferir() {
      return this.info.total_recaudo - this.getTotalDescuentosGenerales();
    },
    getTotalGastosAdministrativos() {
      let total = 0;
      /* if(this.info && this.info.otros){
         for(const d of this.info.otros){
           if(d.id == 2 || d.id == 5){
               total += d.total;
           }
         }
       }*/

      return total;
    },
    getRowsGastosAdministrativos() {
      let rows = [];
      /*if(this.info && this.info.otros){
        for(const d of this.info.otros){
          if(d.id == 2 || d.id == 5){
              rows.push(d);
          }
        }
      }*/
      if (rows.length == 0) {
        return null;
      }
      return rows;
    },
    getTotalDescuentosGenerales() {
      let total = 0;
      if (this.info && this.info.descuentos_generales) {
        for (const d of this.info.descuentos_generales) {
          total += d.total;
        }
      }

      total += this.getTotalGastosAdministrativos();

      return total;
    },
    getTotalDescuentosFinales() {
      let total = 0;
      if (this.info) {
        for (const d of this.info.descuentos_finales) {
          total += d.total;
        }
      }

      return total;
    },
    /* getTotalDescuentosGenerales(){
       let total = 0;
       if(this.info && this.info.descuentos_generales){
         for(const d of this.info.descuentos_generales){
           total += d.total;
         }
       }
       
       return total;
     },*/
    loadItem() {
      this.$http.get('api/admin/liquidacion/get/' + this.$route.params.id)
        .then(res => {
          console.log(res)
          if (res.data.success) {
            this.info = res.data.item;
            this.reloadKey++;
          }
        }).catch(err => {
          console.log(err);
        });
    },
    saveLiquidacion() {
      const data = this.form;
      data.id = this.$route.params.id;
      this.$http.post('api/admin/liquidacion/registrar-pago', { data }).then((resolve) => {
        if (resolve.data.success) {
          this.dialogPay = false;
        }
      })
    },
    formInvalid() {
      console.log(this.form)
      return !this.form._valid;
    },
    btnPayIsVisible() {
      return true;
      return !this.info.pagada;
    }
  }

}
</script>
